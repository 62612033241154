import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import {
  Link, Stack, Text, useDisclosure
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { SingleButton } from 'common/Buttons'
import { SingleInput } from 'common/Inputs'
import { usersSelectors, getNotificationMessage } from 'store'
import { EMAIL, SETTINGS_TYPE, REJECTED } from 'utils/constants'
import { SettingsEmailValidationScheme } from 'validation'

import { SecretDebugger } from './SecretDebugger'

export const Email = ({ user, saveHandler, isLoading }) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(SettingsEmailValidationScheme)
  })

  const { isOpen, onOpen, onClose } = useDisclosure()
  const { activeProfile } = useSelector(usersSelectors)
  const dispatch = useDispatch()

  const onSubmit = (data) => {
    const { email } = data
    const body = {
      type: SETTINGS_TYPE.Email,
      email
    }
    saveHandler(body, 'email')
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing="16px">
        <Text fontSize="20px" fontWeight={400}>
          Change E
          <Link
            href={() => false}
            onClick={() => {
              let testName = process.env.REACT_APP_SPECIAL_TESTERS
              if (testName.includes(activeProfile?.full_name)) {
                onOpen()
              } else {
                dispatch(getNotificationMessage({
                  type: REJECTED,
                  message: 'User is not allowed to debug',
                  isEditProfile: true
                }))
              }
            }}
            sx={{
              'text-decoration': 'none !important'
            }}
          >
            m
          </Link>
          ail Address
        </Text>

        <SingleInput
          register={register(user.email)}
          name="currentEmailDiv"
          fixValue={user.email}
        />
        <SingleInput
          error={errors.email}
          register={register(EMAIL)}
          placeholder="New Email Address"
          name="email"
        />

        <SingleButton
          click={saveHandler}
          isLoading={isLoading}
          text="Save changes"
          name="save-changes-email"
        />
      </Stack>
      <SecretDebugger isOpen={isOpen} onClose={onClose} onOpen={onOpen} />
    </form>
  )
}
