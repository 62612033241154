import { useEffect, useRef } from 'react'
// import ReactDOM from 'react-dom/server'
import { Marker, useMap } from 'react-leaflet'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { ImagesPng } from 'assets/images'
import dayjs from 'dayjs'
import {
  authSelectors,
  chatCredentialsSelectors,
  setIsSignIn,
  setMarkerUpdate,
  setProfileItemId,
  usersSelectors
} from 'store'
import { LOCATION_TYPE, MAX_ZOOM_LEVEL } from 'utils/constants'
import {
  createMarkerColor, hideCounter, showCounter, statusMarkerColor
} from 'utils/helpers'
import { v4 as uuid } from 'uuid'

import { MarkerPopup } from '../MarkerPopup'

export const MarkerItem = ({
  item, storedId, setStoredId, geoLoc, isMyProfile, profileAvatar
}) => {
  const { getProfileItemStatus, activeProfile } = useSelector(usersSelectors)
  const { isSignIn } = useSelector(authSelectors)
  // const { signInStatus } = useSelector(authSelectors)
  const { xmppLiveLocation } = useSelector(chatCredentialsSelectors)
  // bug is present if using storeID selectors to store id param value
  // const { storeId } = useSelector(markersSelectors)
  const map = useMap()

  const [searchParams, setSearchParams] = useSearchParams()

  const dispatch = useDispatch()

  const markerRef = useRef(null)
  // for debug
  // const testAvatar = 'https://i.natgeofe.com/n/548467d8-c5f1-4551-9f58-6817a8d2c45e/NationalGeographic_2572187_square.jpg'
  // console.log(markerAvatars)

  const liveLocCoord = [xmppLiveLocation?.lat, xmppLiveLocation?.lng]
  const validDate = dayjs(item?.last_accessed)
  // eslint-disable-next-line max-len
  const iconMarker = createMarkerColor(validDate, profileAvatar ?? ImagesPng.DefaultImage, item?.is_friends)
  const statusColor = statusMarkerColor(validDate)
  const id = item?.id
  const idParam = searchParams.get('id')

  // const coordinate = {
  //   lat: searchParams.get('lat'),
  //   lng: searchParams.get('lng')
  // }

  const handleClosePopup = () => {
    const mapCenter = map.getCenter()
    const clickedZoom = map.getZoom()

    localStorage.setItem('lat', mapCenter.lat)
    localStorage.setItem('lng', mapCenter.lng)
    localStorage.setItem('zoom', clickedZoom)

    setSearchParams({
      lat: mapCenter.lat.toFixed(6),
      lng: mapCenter.lng.toFixed(6),
      zoom: clickedZoom
    })

    setStoredId(null)
    localStorage.removeItem('id')
  }

  const handleMarkerCloseOnDrag = () => {
    if (localStorage.getItem('id') !== null) {
      map.off('dragend', handleMarkerCloseOnDrag)
      showCounter()

      handleClosePopup()
    }
  }

  // Popup opens runs after closing
  useEffect(() => {
    if (+idParam === id) {
      const marker = markerRef.current
      if (marker) {
        dispatch(setProfileItemId(item.id))
        marker.openPopup()
      }
    }
  }, [searchParams])
  // Same as above
  useEffect(() => {
    if (+idParam === id) {
      const marker = markerRef.current
      if (marker) {
        dispatch(setProfileItemId(item.id))
        marker.openPopup()
        setTimeout(() => dispatch(setIsSignIn(false)), 1000)
      }
    } else if (+storedId === id) {
      const marker = markerRef.current
      if (marker) {
        dispatch(setProfileItemId(item.id))
        // eslint-disable-next-line no-underscore-dangle
        map.setView(marker._latlng, MAX_ZOOM_LEVEL ?? 16, { animate: true })
        setTimeout(() => {
          setSearchParams({ id })
          marker.openPopup()
        }, 410)
      }
    } else if (isSignIn === true && activeProfile?.id === id && +idParam !== id && localStorage.getItem('id') === null) {
      const marker = markerRef.current
      if (marker) {
        // eslint-disable-next-line no-underscore-dangle
        map.setView(marker._latlng, MAX_ZOOM_LEVEL ?? 16)
        setTimeout(() => dispatch(setIsSignIn(false)), 1500)
      }
    }
  }, [])

  return (
    <Marker
      zIndexOffset={-99999}
      eventHandlers={{
        click: (e) => {
          dispatch(setProfileItemId(null))

          map.setView(e.latlng, map.getZoom(), { animate: true })

          setTimeout(() => {
            setSearchParams({ id })
          }, 410)
        },
        popupclose: () => {
          // zoom control, filter container and marker counter
          showCounter()
          // So the marker counter will update
          setTimeout(() => {
            dispatch(setMarkerUpdate(true))
          }, 500)

          handleClosePopup()
        },
        popupopen: () => {
          // zoom control, filter container and marker counter
          hideCounter()

          map.on('dragend', handleMarkerCloseOnDrag)
          // setShowMarkerCount('none')
        }
      }}
      key={uuid()}
      position={
        geoLoc
        && Number.isNaN(xmppLiveLocation.lat) === false
        && item.location_type[LOCATION_TYPE.LiveLocation] === 0
          ? liveLocCoord
          : item?.coordinates
      }
      icon={iconMarker}
      ref={markerRef}
      title={item?.id}
    >
      <MarkerPopup
        getProfileItemStatus={getProfileItemStatus}
        iconMarker={statusColor}
        isMyProfile={geoLoc || isMyProfile ? isMyProfile : item.is_my_profile}
        isFriend={item?.is_friends}
        hasAvatar={profileAvatar}
      />
    </Marker>
  )
}
