import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Select, Stack, Text
} from '@chakra-ui/react'
import { SingleButton } from 'common/Buttons'
import { getFrequency, usersSelectors } from 'store'
import { SETTINGS_TYPE } from 'utils/constants'

export const Notifications = ({ saveHandler, isLoading }) => {
  const [notifFrequency, setNotifFrequency] = useState()
  const { user, frequency } = useSelector(usersSelectors)
  const [frequencyArray, setFrequencyArray] = useState()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getFrequency())
  }, [])

  const onSubmit = (data) => {
    // const { notif } = data
    const body = {
      type: SETTINGS_TYPE.Notif,
      frequency: data
    }
    // console.log(notif)
    saveHandler(body, 'frequency')
  }

  useEffect(() => setFrequencyArray(frequency), [frequency])
  return (
    <Stack spacing="16px">
      <Text fontSize="20px" fontWeight={400}>
        Notification
      </Text>
      <Stack spacing="16px">
        <Stack>
          <Text>Email Notification Frequency</Text>
          <Select name="frequency" onChange={(e) => setNotifFrequency(e.target.value)}>
            <option value="" selected={user.frequency === null ? 'selected' : false}>None</option>
            {/* <option value="daily" selected={user.frequency === 'daily' ? 'selected' : false}>
            Daily</option>
            <option value="weekly" selected={user.frequency === 'weekly' ? 'selected' : false}>
            Weekly</option> */}
            {frequencyArray?.map((item) => {
              const firstLetter = item?.charAt(0).toUpperCase()
              const word = item?.substring(1)
              return (
                <option value={item} selected={user.frequency === item && item !== null ? 'selected' : false}>
                  {
                    Number.isNaN(item?.charAt(0)) === false ? firstLetter + word : item
                  }
                </option>
              )
            })}
          </Select>
        </Stack>
        <SingleButton
          click={() => onSubmit(notifFrequency)}
          isLoading={isLoading}
          text="Save changes"
          name="save-changes-notification"
        />
      </Stack>
    </Stack>
  )
}
