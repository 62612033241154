import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box, Center, Flex, Img, Input, Stack, Text
} from '@chakra-ui/react'
import { CloseIcon } from 'assets/icons'
import { useDebounce } from 'hooks/useDebounce'
import {
  getNotificationMessage,
  getSocialLinks,
  getSocialLinksCategories,
  notificationMessageSelector,
  setSocMedDisplay,
  setSocMedDisplayStatus,
  usersSelectors
} from 'store'
import { REJECTED } from 'utils/constants'
import { COLORS } from 'utils/styles'
import { v4 as uuid } from 'uuid'

import { SocialProfileSelect } from './SocialProfileSelect'

export const SocialProfiles = ({
  // socialLinks,
  // setSocialLinks,
  socialLinksArray,
  setSocialLinksArray,
  searchSocialLinks,
  searchSocialLinksCategories,
  setIsSocialLinkSelected
  // register,
  // error
}) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [socialLinksInput, setSocialLinksInput] = useState('')
  const [socialLinkDisplay, setSocialLinkDisplay] = useState('none')
  const [socialLinkSelected, setSocialLinkSelected] = useState([])
  const [placeholderDisplay, setPlaceholderDisplay] = useState('inline')
  const [errorMessage, setErrorMessage] = useState('')
  const { socMedDisplay } = useSelector(usersSelectors)
  const { editProfileSocialLink } = useSelector(notificationMessageSelector)

  const debouncedValue = useDebounce(socialLinksInput, 500)

  const socialLinksListRef = useRef(null)
  const inputSRef = useRef(null)

  const dispatch = useDispatch()

  // removing a link from the list
  const handleDeleteCodeWord = (id) => {
    const newArray = socialLinksArray.filter((item) => item.id !== id)
    setSocialLinksArray(newArray)
  }

  // Adding a Social Link in the List
  const handleAddSocialLinks = (e, socialLinkItem) => {
    if (socialLinksInput === '') {
      dispatch(
        getNotificationMessage({
          type: REJECTED,
          message: 'Please type a link!',
          isEditProfile: true
        })
      )
    } else if (socialLinksInput?.length > 100) {
      dispatch(
        getNotificationMessage({
          type: REJECTED,
          message: 'Link should not exceed 100 characters',
          isEditProfile: true
        })
      )
    } else if (socialLinksArray?.length >= 6) {
      setErrorMessage('Only 6 social links are allowed')
      setSocialLinksInput('')
    } else {
      setSocialLinksArray([...socialLinksArray, {
        id: socialLinkItem.id,
        name: socialLinkItem.name,
        icon: socialLinkItem.icon,
        link: socialLinksInput
      }])
      setErrorMessage('')
      setSocialLinkSelected([])
      setIsPopupOpen(false)
      setSocialLinksInput('')
      setIsSocialLinkSelected(false)
    }
  }

  const handleOpenSocialLink = () => {
    setSocialLinkDisplay('inline-block')
    dispatch(setSocMedDisplay(true))
    dispatch(setSocMedDisplayStatus('inline-block'))
  }

  const handleSocialLinkInput = (e) => {
    setSocialLinksInput(e)
    if (e) {
      setPlaceholderDisplay('none')
    } else {
      setPlaceholderDisplay('inline')
    }
  }

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (
        socialLinksListRef.current
        && !socialLinksListRef.current.contains(e.target)
        && !inputSRef.current.contains(e.target)
      ) {
        setIsPopupOpen(false)
      }
    }
    // https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#once
    // If {once: true}, the listener would be automatically removed when invoked.
    document.body.addEventListener('mousedown', handleOutsideClick, { once: true })
  }, [isPopupOpen])

  useEffect(() => {
    dispatch(getSocialLinks(debouncedValue))
    dispatch(getSocialLinksCategories(debouncedValue))
  }, [debouncedValue])

  useEffect(() => {
    setSocialLinkDisplay(socMedDisplay)
  }, [socMedDisplay])

  return (
    <Stack gap="1rem">
      <Flex gap={2}>
        <Text fontSize="20px" fontWeight={700}>
          Social Profiles
        </Text>
      </Flex>

      <SocialProfileSelect
        socialLinkList={searchSocialLinks}
        setSocialLinkSelected={setSocialLinkSelected}
        socialListDisplay={socialLinkDisplay}
        setSocialLinkDisplay={setSocialLinkDisplay}
        socialLinksArray={socialLinksArray}
        searchSocialLinksCategories={searchSocialLinksCategories}
        setIsSocialLinkSelected={setIsSocialLinkSelected}
      />

      <Flex
        direction="row"
        width="100%"
        h="42px"
        border="1px"
        borderRadius="5px"
        borderColor="gray.300"
        // sx={{
        //   ':active': {
        //     border: '2px',
        //     borderColor: 'blue.400'
        //   }
        // }}
      >
        {socialLinkSelected.icon ? (
          <Box w="40px" name="select-social-button" bgColor="gray.200" borderRadius="5px 0px 0px 5px" zIndex={2} alignItems="center" onClick={() => handleOpenSocialLink()}>
            <Center h="100%">
              <Img src={socialLinkSelected.icon} borderRadius="5px 0px 0px 5px" w="100%" />
            </Center>
          </Box>
        ) : null}
        {socialLinkSelected.url
          ? (
            <Stack position="relative" w="90%">
              <Input
                w="100%"
                fontSize="16px !important"
                height="40px"
                focusBorderColor="none"
                ref={inputSRef}
                data-placeholder={socialLinkSelected.url}
                value={socialLinksInput}
                onChange={(e) => handleSocialLinkInput(e.target.value)}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') {
                    handleAddSocialLinks(e, socialLinkSelected)
                    setPlaceholderDisplay('inline')
                  }
                }}
                border="none"
                name="profile_social_links"
                className="soc-med-input"
              />
              <Box position="absolute" w="95%" h="40px" marginTop="6px" display={placeholderDisplay} overflowX="hidden">
                <Text className="sample" fontSize="16px">
                  {'Paste address here: ' + socialLinkSelected.url}
                </Text>
              </Box>
            </Stack>
          ) : (
            <Input
              w="100%"
              fontSize="16px !important"
              border="none"
              height="40px"
              placeholder="Select Social Link"
              defaultValue={socialLinksInput}
              onFocus={() => handleOpenSocialLink()}
              name="profile_social_links-select"
            />
          )}
      </Flex>

      {/* List of Social Links Added */}
      <Stack
        rowGap="1rem"
        columnGap="10px"
        spacing="unset"
        wrap="wrap"
        direction="row"
        display={socialLinksArray?.length > 0 ? 'flex' : 'none'}
      >
        {socialLinksArray?.map((item) => (
          <Stack
            key={uuid()}
            direction="row"
            spacing="10px"
            // mr="5px !important"
            p="6px 18px"
            borderRadius="full"
            bg={COLORS.gold}
            width="max-content"
            alignItems="center"
          >
            <Text>{item.name}</Text>
            <Box onClick={() => handleDeleteCodeWord(item.id)} cursor="pointer" name="delete-social-link">
              <CloseIcon />
            </Box>
          </Stack>
        ))}
      </Stack>
      {socialLinksArray?.length > 0 ? (
        <Text fontSize="14px" color={COLORS.red}>
          {errorMessage}
        </Text>
      ) : null}
      {editProfileSocialLink?.length > 0 ? (
        <Text fontSize="14px" color={COLORS.red}>
          Invalid URLs on&#58;
          <br />
          {editProfileSocialLink?.map((item, i) => item + (i === editProfileSocialLink?.length - 1 ? '' : ',\n'))}
        </Text>
      ) : null}
    </Stack>
  )
}
