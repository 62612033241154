import { Box, Stack, Text } from '@chakra-ui/react'
import { COLORS } from 'utils/styles'
import { v4 as uuid } from 'uuid'

export const CodeWords = ({ codeWords }) => (
  <Stack direction="column" spacing="1rem">
    <Text fontSize="20px" fontWeight={700}>
      Code Words
    </Text>
    {codeWords?.length > 0 ? (
      <Stack gap="1rem 10px" spacing="unset" flexWrap="wrap" direction="row">
        {codeWords.map((item) => (
          <Box
            key={uuid()}
            borderRadius="full"
            // bg={COLORS.lightGray}
            bg={COLORS.gold}
            p="6px 10px"
            data-name="codewords"
          >
            #
            {item.title}
          </Box>
        ))}
      </Stack>
    ) : (
      <Text color={COLORS.gray}>No data available</Text>
    )}
  </Stack>
)
