import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import {
  Checkbox, Image, Stack, Text
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { ImagesPng } from 'assets/images'
import { SingleButton } from 'common/Buttons'
import { SingleInput } from 'common/Inputs'
import { PasswordInput } from 'common/Inputs/PasswordInput'
import { handleModalTitle, signIn } from 'store'
import {
  EMAIL,
  FORGOT_PASSWORD,
  PASSWORD,
  SIGN_UP,
  VERIFY_PAGE
} from 'utils/constants'
import { COLORS } from 'utils/styles'
import { SignInValidationScheme } from 'validation'

export const SignIn = ({ setAuthPage, isLoading }) => {
  const dispatch = useDispatch()

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(SignInValidationScheme)
  })

  const onSubmit = (data) => {
    const body = {
      username: data.email,
      password: data.password
    }
    dispatch(signIn(body))
  }

  useEffect(() => {
    dispatch(handleModalTitle(VERIFY_PAGE.SignIn))
  }, [])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="column" spacing="32px">
        <Image
          boxSize="72px"
          objectFit="cover"
          src={ImagesPng.Logo}
          alt="Logo"
          borderRadius="full"
          margin="auto"
        />

        <Stack
          onClick={() => dispatch(setAuthPage(SIGN_UP))}
          cursor="pointer"
          sx={{ justifyContent: 'center' }}
          direction="row"
        >
          <Text fontSize="14px">New to Connector?</Text>
          <Text color={COLORS.gold} fontSize="14px" name="register">
            Create Account here.
          </Text>
        </Stack>

        <Stack spacing="1rem" direction="column">
          <SingleInput
            placeholder="Email Address"
            error={errors.email}
            register={register(EMAIL)}
            name="email"
          />
          <PasswordInput
            placeholder="Password"
            error={errors.password}
            register={register(PASSWORD)}
            name="password"
          />
        </Stack>

        <Stack sx={{ justifyContent: 'space-between' }} direction="row">
          <Checkbox size="md" name="remember">
            <Text color={COLORS.darkGray} fontSize="14px">
              Remember me
            </Text>
          </Checkbox>
          <Text
            onClick={() => dispatch(setAuthPage(FORGOT_PASSWORD))}
            color={COLORS.darkGray}
            cursor="pointer"
            fontSize="14px"
            name="forgot"
          >
            Forgot Password?
          </Text>
        </Stack>
        <SingleButton isLoading={isLoading} text="Sign In" name="signIn" />
      </Stack>
    </form>
  )
}
