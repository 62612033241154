import { Box, Stack, Text } from '@chakra-ui/react'
import { MinusIcon } from 'assets/icons'
import { FILTERS_TYPE } from 'utils/constants'
import { COLORS } from 'utils/styles'

export const FilterItem = ({
  item, deleteSearchBarFilter, setIsShow, length
}) => {
  const filterColor = () => {
    if (item.type === FILTERS_TYPE.Affinities || item.type === FILTERS_TYPE.CodeWord) {
      return COLORS.gold_op8
    }
    return COLORS.gray_op8
  }

  return (
    <Box
      position="relative"
      height="fit-content"
      marginBottom="16px"
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        backgroundColor={filterColor()}
        minWidth="200px"
        padding="4px 8px 4px 18px"
        alignItems="center"
        borderRadius="5px"
        cursor="pointer"
        onMouseOver={() => setIsShow(item.type === FILTERS_TYPE.Own && true)}
        onMouseOut={() => setIsShow(false)}
        onClick={() => {
          deleteSearchBarFilter(item.id)
          if (length === 1) {
            localStorage.setItem('is-filtered', false)
          }
        }}
      >
        <Stack direction="row" spacing={0} alignItems="center">
          {item.type === FILTERS_TYPE.CodeWord && !item.title.includes('#') ? <Box>#</Box> : null}
          <Text data-name="filterName">{item.title}</Text>
        </Stack>

        <Box cursor="pointer">
          <MinusIcon />
        </Box>
      </Stack>
    </Box>
  )
}
