import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { Image, Stack, Text } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { ImagesPng } from 'assets/images'
import { SingleButton } from 'common/Buttons'
import { SingleInput } from 'common/Inputs'
import { PasswordInput } from 'common/Inputs/PasswordInput'
import { handleModalTitle, signUp } from 'store'
import {
  CONFIRM_PASSWORD,
  EMAIL,
  PASSWORD,
  SIGN_IN,
  VERIFY_PAGE
} from 'utils/constants'
import { COLORS } from 'utils/styles'
import { SignUpValidationScheme } from 'validation'

export const SignUp = ({ setAuthPage, isLoading }) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(SignUpValidationScheme)
  })

  const dispatch = useDispatch()

  const onSubmit = (data) => {
    const body = {
      email: data.email,
      password: data.confirmPassword
    }
    dispatch(signUp(body))
  }

  useEffect(() => {
    dispatch(handleModalTitle(VERIFY_PAGE.SignUp))
  }, [])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="column" spacing="32px">
        <Image
          boxSize="72px"
          objectFit="cover"
          src={ImagesPng.Logo}
          alt="Logo"
          borderRadius="full"
          margin="auto"
        />

        <Stack spacing="1rem" direction="column">
          <SingleInput
            placeholder="Email Address"
            error={errors.email}
            register={register(EMAIL)}
            name="email"
          />
          <PasswordInput
            placeholder="Password"
            error={errors.password}
            register={register(PASSWORD)}
          />
          <PasswordInput
            placeholder="Repeat Password"
            error={errors.confirmPassword}
            register={register(CONFIRM_PASSWORD)}
          />
        </Stack>

        <Stack
          onClick={() => dispatch(setAuthPage(SIGN_IN))}
          cursor="pointer"
          justifyContent="center"
          direction="row"
        >
          <Text fontSize="14px">Already have an account?</Text>
          <Text color={COLORS.gold} fontSize="14px">
            Sign in here.
          </Text>
        </Stack>

        <SingleButton isLoading={isLoading} text="Sign Up" />
      </Stack>
    </form>
  )
}
