import { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Stack
} from '@chakra-ui/react'
import {
  acceptFriend, denyFriend, usersSelectors
} from 'store'
import { NOTIFICATION_TYPE, PENDING } from 'utils/constants'
import { COLORS } from 'utils/styles'
import { v4 as uuid } from 'uuid'

import { ConnectionsRequest } from './ConnectionsRequest'

export const FriendsNotifications = ({
  getFriendsNotificationsStatus,
  getBlockedRequestStatus
}) => {
  const {
    friendsNotifications, acceptFriendStatus, denyFriendStatus, activeProfile
  } = useSelector(usersSelectors)

  const [selectId, setSelectId] = useState()
  const cardRef = useRef()

  const dispatch = useDispatch()

  const handleNotificationButton = ({ id, type }) => {
    if (type === NOTIFICATION_TYPE.Accept) {
      dispatch(acceptFriend({
        id,
        activeId: activeProfile?.id
      }))
    } else {
      dispatch(denyFriend({
        id,
        activeId: activeProfile?.id
      }))
    }
    setSelectId(id)
  }

  return (
    <Card key={uuid()} ref={cardRef} boxShadow="none" marginTop="0px !important" background="#c4c3c7">
      <CardHeader display="flex" alignItems="center" padding="0 10px" background="#feeb88">
        <Box textAlign="left" paddingRight="10px">
          Connection Request
        </Box>
        {friendsNotifications.length > 0 && (
          <Stack direction="row" alignItems="center">
            <Box
              textAlign="center"
              background={COLORS.gold}
              width="17px"
              height="17px"
              borderRadius="full"
              position="relative"
            >
              <Box
                fontSize="12px"
                fontWeight={500}
                transform="translate(-50%, -50%)"
                top="50%"
                left="50%"
                position="absolute"
              >
                {friendsNotifications.length > 99 ? '99+' : friendsNotifications.length}
              </Box>
            </Box>
          </Stack>
        )}
      </CardHeader>

      <CardBody padding="5px" minHeight="130px">
        <Box
          paddingBottom={friendsNotifications.length > 3 ? '5px' : null}
          sx={{
            overflowX: 'auto',
            display: 'flex',
            flexDirection: 'row',
            gap: '0px'
          }}
          css={{
            '&::-webkit-scrollbar': {
              width: '4px',
              height: '10px',
              display: 'block'
            },
            '&::-webkit-scrollbar-track': {
              width: '6px'
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: '24px',
              background: 'rgba(255, 215, 0, 1)'
            }
          }}
        >
          {friendsNotifications.length ? (
            <ConnectionsRequest
              handleNotificationButton={handleNotificationButton}
              notifications={friendsNotifications}
              isAcceptLoading={acceptFriendStatus === PENDING}
              isRejectLoading={denyFriendStatus === PENDING}
              getFriendsNotificationsStatus={getFriendsNotificationsStatus}
              getBlockedRequestStatus={getBlockedRequestStatus}
              selectId={selectId}
            />
          ) : <div>No notifications to display</div>}
          {/* <ContentItem items={item?.value} title={item?.id} /> */}
        </Box>
      </CardBody>
    </Card>
  )
}
