import { Link } from 'react-router-dom'
import { Box, Stack } from '@chakra-ui/react'
import { COLORS } from 'utils/styles'

export const Links = () => (
  <Stack fontSize="14px" color={COLORS.gray} alignItems="center" gap="1rem" justifyContent="center">
    <Box>
      <Link to="/terms-of-service" cursor="pointer">
        Terms of Service & User Agreement
      </Link>
    </Box>
    <Box
      gap="10px"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      flexDirection="row"
    >
      <Link to="/FAQ" cursor="pointer">
        FAQ
      </Link>
      <Link to="/privacy-policy" cursor="pointer">
        Privacy Policy
      </Link>
      <Link to="/about-us" cursor="pointer">
        About
      </Link>
      <Link to="/contact-us" cursor="pointer">
        Contact
      </Link>
    </Box>
  </Stack>
)
