import { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box, Image, useDisclosure, useOutsideClick
} from '@chakra-ui/react'
import { ImagesPng } from 'assets/images'
import { postMakeNotificationSeen, usersSelectors } from 'store'
import { v4 as uuid } from 'uuid'

import { ContentModal } from '../ContentModal'

export const ContentItem = ({ items, title }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [selectedItem, setSelectedItem] = useState({})
  const [isNotifModalOpen, setIsNotifModalOpen] = useState(false)
  const {
    isNotifViewing, getProfileItemStatus, getBlockedRequestStatus, getFriendsNotificationsStatus
  } = useSelector(usersSelectors)

  const notifModalRef = useRef()

  const dispatch = useDispatch()

  const handleOpenModal = (item) => {
    setIsNotifModalOpen(true)
    onOpen()
    setSelectedItem(item)
  }

  const handleCloseModal = () => {
    onClose()
    setIsNotifModalOpen(false)
    localStorage.removeItem('id')
    localStorage.setItem('is-notif-modal-open', 'false')
  }
  window.addEventListener('beforeunload', () => {
    localStorage.setItem('is-notif-modal-open', 'false')
  })

  if (!items.length) {
    return <div>No notifications to display</div>
  }
  return (
    <>
      {[...items].reverse().map((item) => (
        <Box
          key={uuid()}
          cursor="pointer"
          onClick={() => {
            if (item.is_viewed
              && getProfileItemStatus !== 'PENDING'
              && getBlockedRequestStatus !== 'PENDING'
              && getFriendsNotificationsStatus !== 'PENDING'
            ) {
              handleOpenModal(item)
            } else if (getProfileItemStatus === 'PENDING') {
              console.log('stop')
            } else {
              dispatch(postMakeNotificationSeen(item?.id))
            }
            setTimeout(() => localStorage.setItem('is-notif-modal-open', 'true'), 500)
          }}
          onLoad={() => {
            if (localStorage.getItem('openedNotifId') === String(item?.id)) {
              localStorage.removeItem('openedNotifId')
              handleOpenModal(item)
            }
          }}
          sx={{
            minWidth: '120px',
            marginTop: '5px'
          }}
          data-name="notificationItem"
          pointerEvents={isNotifViewing === false ? 'auto' : 'none'}
        >
          <Image
            // boxSize="70px"
            width="108px"
            height="108px"
            objectFit="cover"
            src={item?.image || ImagesPng.DefaultImage}
            alt={item?.title}
            opacity={isNotifViewing === false ? 1 : 0.7}
            borderRadius="5px"
          />
        </Box>
      ))}

      <ContentModal
        ref={notifModalRef}
        item={selectedItem}
        onClose={handleCloseModal}
        isNotifModalOpen={isNotifModalOpen}
        isOpen={isOpen}
        title={title}
      />
    </>
  )
}
