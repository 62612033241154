import { useForm } from 'react-hook-form'
import { Stack, Text } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { SingleButton } from 'common/Buttons'
import { PasswordInput } from 'common/Inputs/PasswordInput'
import {
  CONFIRM_PASSWORD,
  CURRENT_PASSWORD,
  PASSWORD,
  SETTINGS_TYPE
} from 'utils/constants'
import { SettingsPasswordValidationScheme } from 'validation'

export const Password = ({ saveHandler, isLoading }) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(SettingsPasswordValidationScheme)
  })

  const onSubmit = (data) => {
    const { password, currentPassword, confirmPassword } = data
    const body = {
      type: SETTINGS_TYPE.Password,
      password,
      password_re: confirmPassword,
      password_current: currentPassword
    }
    saveHandler(body, 'password')
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing="16px">
        <Text fontSize="20px" fontWeight={400}>
          Change Password
        </Text>

        <PasswordInput
          error={errors.currentPassword}
          register={register(CURRENT_PASSWORD)}
          placeholder="Current Password"
        />
        <PasswordInput
          error={errors.password}
          register={register(PASSWORD)}
          placeholder="New Password"
        />
        <PasswordInput
          error={errors.confirmPassword}
          register={register(CONFIRM_PASSWORD)}
          placeholder="Confirm New Password"
        />

        <SingleButton isLoading={isLoading} name="save-changes-password" text="Save changes" />
      </Stack>
    </form>
  )
}
